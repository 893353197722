// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/';
const ROOTS_USER = '/user';
const ROOTS_PROFILE = '/user/Profile';
const ROOTS_PROJECT = '/user/Project';
const ROOTS_CAREER = '/user/Career';
const ROOTS_SESSION = '/user/Session';
const ROOTS_INTERVIEW = '/user/Interview';
const ROOTS_ATTENDANCE = '/user/Attendance';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
};

export const NOT_FOUND = '/404';

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, 'dashboard'),
  teams: path(ROOTS_DASHBOARD, 'teams'),
  teamDetails: path(ROOTS_DASHBOARD, 'teams/details'),
  employees: path(ROOTS_DASHBOARD, 'employees'),
  employeeDetails: path(ROOTS_DASHBOARD, 'employees/details'),
  rawTaskDetails: path(ROOTS_DASHBOARD, 'training/task/details'),
  rawTaskMapDetails: path(ROOTS_DASHBOARD, 'training/task/mapping-details'),
  interviews: path(ROOTS_DASHBOARD, 'interviews'),
  addInterviews: path(ROOTS_DASHBOARD, 'interviews/addInterviews'),
  // editInterviews: path(ROOTS_DASHBOARD, '/interviews/editInterviews'),
  viewRounds: path(ROOTS_DASHBOARD, 'interviews/viewRounds'),
  sessions: path(ROOTS_DASHBOARD, 'sessions/list'),
  sessionList: path(ROOTS_DASHBOARD, 'sessions'),
  sessionAttendance: (id: string) => path(ROOTS_DASHBOARD, `session/attendance/${id}`),
  attendance: path(ROOTS_DASHBOARD, 'attendance'),
  employeeAttendance: (id: string) => path(ROOTS_DASHBOARD, `employees/details/attendance/${id}`),
  teamAttendance: (id: string) => path(ROOTS_DASHBOARD, `teams/details/attendance/${id}`),
  interviewDetails: path(ROOTS_DASHBOARD, 'interviews/details'),
  listTasks: path(ROOTS_DASHBOARD, 'training/tasks'),
  training: path(ROOTS_DASHBOARD, 'training'),
  addTask: path(ROOTS_DASHBOARD, 'training/task/add'),
  editTask:path(ROOTS_DASHBOARD, 'training/task/edit'),
  taskDetails: path(ROOTS_DASHBOARD, 'training/task/:id'),
  dailyStatus: path(ROOTS_DASHBOARD, 'training/dailystatus'),
  assignedTasks: path(ROOTS_DASHBOARD, 'training/assigned'),
  traineeList: path(ROOTS_DASHBOARD, 'training/trainee/list'),
  review: path(ROOTS_DASHBOARD, 'reviews'),
  addReview: path(ROOTS_DASHBOARD, 'review/add'),
  editReview:path(ROOTS_DASHBOARD, 'review/edit'),
  reviewDetail:(id: string) => path(ROOTS_DASHBOARD, `reviews/details/${id}`),
  user: {
    root: ROOTS_USER,
    Profile: {
      root: ROOTS_PROFILE,
      editProfile: path(ROOTS_PROFILE, '/editProfile'),
      editSkills: path(ROOTS_PROFILE, '/editSkills'),
    },

    Project: {
      root: ROOTS_PROJECT,
      addProjects: path(ROOTS_PROJECT, '/addProjects'),
      editProjects: path(ROOTS_PROJECT, '/editProjects'),
    },
    Career: {
      root: ROOTS_CAREER,
      addCareer: path(ROOTS_CAREER, '/addCareer'),
      editCareer: path(ROOTS_CAREER, '/editCareer'),
    },

    Session: {
      root: ROOTS_SESSION,
    },

    Interview: {
      root: ROOTS_INTERVIEW
    },
    Attendance: {
      root: ROOTS_ATTENDANCE
    },
  }
};
