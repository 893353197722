import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// permission
import { PermissionModule } from 'src/model/roles.model';
import { checkPermissions } from 'src/pages/common/util';
// auth

import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  Page404,
  Home,
  Teams,
  UserProfile,
  LoginPage,
  EmployeeSessions,
  Employees,
  Interviews,
  SessionAttendance,
  AddProjects,
  EditProfile,
  EditSkills,
  AddCareer,
  CandidateForm,
  Attendance,
  InterviewDetails,
  EmployeeDetails,
  TeamDetails,
  InterviewRounds,
  Tasks,
  AddTask,
  TaskDetails,
  TaskMapDetails,
  SessionList,
  DailyStatus,
  AssignedTask,
  ListTrainees,
  Review,
  ReviewAdd,
  ReviewEdit,
  ReviewDetail
} from './elements';
import { DefaultStore } from '../model/store.model';

// ----------------------------------------------------------------------

export default function Router() {
  const { isAuthenticated } = useSelector((state: DefaultStore) => state.auth);

  const checkPermissionAndRender = (moduleName: PermissionModule, permission: string, element: React.ReactNode) => {
    if (checkPermissions(moduleName, permission)) {
      return element;
    }
    return <Navigate to="/" replace />;

  };

  return useRoutes([
    {
      path: '/',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashboard', element: <Home /> },
        { path: 'sessions/list', element: checkPermissionAndRender('session', 'list', <SessionList />), },
        { path: 'teams', element: checkPermissionAndRender('teams', 'list', <Teams />) },
        { path: 'teams/details/:id', element: checkPermissionAndRender('teams', 'details', <TeamDetails />) },
        { path: 'employees', element: checkPermissionAndRender('employees', 'employee-list', <Employees />) },
        { path: 'employees/details/:id', element: checkPermissionAndRender('employees', 'user.details', <EmployeeDetails />) },
        { path: 'employees/details/attendance/:id', element: checkPermissionAndRender('employees', 'user.details', <EmployeeDetails />) },
        { path: 'sessions', element: checkPermissionAndRender('session', 'list', <EmployeeSessions />) },
        { path: 'interviews', element: checkPermissionAndRender('interviews', 'list', <Interviews />) },
        { path: 'interviews/addInterviews/:interview_uuid?/:interview_round_uuid?', element: checkPermissionAndRender('interviews', 'add', <CandidateForm />) },
        // { path: 'interviews/editInterviews/:id', element: checkPermissionAndRender('interviews', 'update', <AddInterviews />) },
        { path: 'interviews/viewRounds/:interview_uuid?/:interview_round_uuid?', element: checkPermissionAndRender('interviews', 'add-round', <InterviewRounds />) },
        { path: 'session/attendance/:id', element: checkPermissionAndRender('session', 'details', <SessionAttendance />) },
        { path: 'attendance', element: checkPermissionAndRender('attendance', 'list', <Attendance />) },
        { path: 'interviews/details/:id', element: checkPermissionAndRender('interviews', 'details', <InterviewDetails />) },
        { path: 'training/tasks', element: checkPermissionAndRender('task', 'list', <Tasks />) },
        { path: 'training', element: checkPermissionAndRender('task', 'list', <Tasks />) },
        { path: 'training/task/add', element: checkPermissionAndRender('task', 'add', <AddTask />) },
        { path: 'training/task/edit/:id', element: checkPermissionAndRender('task', 'add', <AddTask />) },
        { path: 'training/dailystatus', element: checkPermissionAndRender('dailystatus', 'list', <DailyStatus />) },
        { path: 'training/assigned', element: checkPermissionAndRender('assigned_task', 'list', <AssignedTask />) },
        { path: 'training/trainee/list', element: checkPermissionAndRender('trainees', 'list', <ListTrainees />) },
        { path: 'reviews', element: checkPermissionAndRender('task', 'list', <Review />) },
        { path: 'review/add', element: checkPermissionAndRender('task', 'list', <ReviewAdd />) },
        { path: 'review/edit/:id/:action/:uuid', element: checkPermissionAndRender('task', 'list', <ReviewEdit />) },
        { path: 'reviews/details/:review_uuid', element: checkPermissionAndRender('task', 'list', <ReviewDetail />) },
        { path: 'training/task/details/:id', element: checkPermissionAndRender('task', 'list', <TaskDetails />) },
        { path: 'training/task/mapping-details/:id', element: checkPermissionAndRender('assigned_task', 'details', <TaskMapDetails />) },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/user/profile" replace />, index: true },
            {
              path: 'Profile',
              children: [
                { path: '', element: <UserProfile /> },
                { path: 'editProfile', element: <EditProfile /> },
                { path: 'editSkills', element: <EditSkills /> },
              ],
            },
            {
              path: 'Career',
              children: [
                { path: '', element: <UserProfile /> },
                { path: 'addCareer', element: <AddCareer /> },
                { path: 'editCareer/:id', element: <AddCareer /> },
              ],
            },
            {
              path: 'Project',
              children: [
                { path: '', element: <UserProfile /> },
                { path: 'addProjects', element: <AddProjects /> },
                { path: 'editProjects/:id', element: <AddProjects /> },
              ],
            },

            {
              path: 'Session',
              children: [
                { path: '', element: <UserProfile /> }
              ],
            },
            {
              path: 'Interview',
              children: [
                { path: '', element: <UserProfile /> }
              ],
            },
            {
              path: 'Attendance',
              children: [
                { path: '', element: <UserProfile /> }
              ],
            },
          ],
        }
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
