import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import Api from 'src/utils/apiUrls';
import axiosInstance from 'src/utils/axios';
// config
import { PAGINATION_LINK } from '../config-global';

interface AttendanceState {
  isLoading: boolean;
  error: {};
  locationList: [];
  attendanceData: [];
  totalData: 0,
  employeeAttendance: [];
}

const initialState: AttendanceState = {
  isLoading: false,
  error: {},
  locationList: [],
  attendanceData: [],
  totalData: 0,
  employeeAttendance: [],
};

export const AttendanceSlice = createSlice({
  name: 'attendance',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
      state.error = {};
    },
    setAttendanceData(state, action) {
      state.attendanceData = action.payload.attendance;
      state.totalData = action.payload.total_count;
    },
    setEmployeeAttendanceData(state, action) {
      state.employeeAttendance = action.payload[0].attendances;
    },
    setAttendanceError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { setLoading, setAttendanceError, setAttendanceData, setEmployeeAttendanceData } =
  AttendanceSlice.actions;
export default AttendanceSlice.reducer;

export const fetchAttendance = createAsyncThunk(
  'Attendance/fetchAttendance',
  async (
    {
      from_date,
      to_date,
      user_uuid = [],
      vt_team_uuid = '',
      page = 0,
      limit = PAGINATION_LINK.PER_PAGE_COUNT,
      sort = '',
      sortKey = ''
    }: { from_date: string; to_date: string; user_uuid?: any[]; vt_team_uuid?: any, page?: number, limit?: number, sort?: string, sortKey?: string },
    { dispatch }
  ) => {
    try {
      dispatch(setLoading(true));
      const response = await axiosInstance.get(`${Api.attendance}${Api.list}`, {
        params: {
          from_date,
          to_date,
          user_uuid,
          vt_team_uuid,
          skip: `${page * PAGINATION_LINK.PER_PAGE_COUNT}`,
          limit,
          sort,
          sort_key: sortKey
        },
      });
      dispatch(setAttendanceData(response?.data?.data));
      dispatch(setLoading(false));
    } catch (error) {
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setAttendanceError(error.message));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setAttendanceError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);

export const updateAttendance = createAsyncThunk(
  'Attendance/updateAttendance',
  async (data: any) => {
    try {
      await axiosInstance.post(Api.attendance + Api.edit, data);
      return { success: true }; // Return success flag
    } catch (error) {
      const errorMessages = Object.values(error?.data).flat();
      throw error; // Throw the error to indicate failure
    }
  }
);

export const fetchEmployeeAttendance = createAsyncThunk(
  'Attendance/fetchEmployeeAttendance',
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      // const response = await axios.get(`${Api.employeeAttendance}/${token}`);
      const response = {
        status: true,
        message: 'Attendance data listed successfully.',
        data: [
          {
            uuid: 'd3bd7412-24f5-4674-b877-a2fa21261055',
            name: 'Joseph',
            email: 'joseph.john@qburst.com',
            email_verified_at: null,
            created_at: '2023-08-03T07:36:55.000000Z',
            updated_at: '2023-08-03T07:36:55.000000Z',
            deleted_at: null,
            attendances: {
              '2023-07-24': 'present',
              '2023-08-04': 'present',
            },
          },
        ],
      };

      // dispatch(setAttendanceData(response?.data?.data));
      dispatch(setEmployeeAttendanceData(response?.data));
      dispatch(setLoading(false));
    } catch (error) {
      if (error?.data?.length === 0) {
        // Custom message handled
        dispatch(setAttendanceError(error.message));
      } else {
        // Specific error message handled
        const errorMessages = Object.values(error?.data).flat();
        dispatch(setAttendanceError(errorMessages));
      }
      throw error; // Throw the error to indicate failure
    }
  }
);
