import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import Api from '../utils/apiUrls';
import { ReviewState } from '../model/review.model';
// config
import { PAGINATION_LINK } from '../config-global';

export interface Review {
  uuid?: string;
  name?: string;
  mentor_rating?: number | null;
  lead_rating?: number | null;
}

const initialState: ReviewState = {
  isLoading: false,
  reviewsData: [],
  reviewsDetails:{},
  reviewOverallData:[],
  reviewFactorData:[],
  reviewTypes:[],
  reviewsCount:0,
  error: {},
};

export const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setReviewList(state, action) {
      const reviews = action.payload.reviews;
      const reviewsCount = action.payload.count;
      state.reviewsData = reviews;
      state.reviewsCount = reviewsCount;
    },
    setReviewDetails(state, action) {
      const reviewDetails = action.payload;
      state.reviewsDetails = reviewDetails;
      state.reviewOverallData=reviewDetails[0];
      state.reviewFactorData=reviewDetails.slice(1);
    },
    setReviewTypes(state, action) {
      const reviewTypes = action.payload;
      state.reviewTypes = reviewTypes;
    },
    setAssignedTasks(state, action) {
      const assignedTasks = action.payload;
      state.assignedTasks = assignedTasks.assigned_tasks;
    },

    setTasksError(state, action) {
      state.error = action.payload;
    },
  },
});
export const {
  setReviewList,
  setReviewDetails,
  setReviewTypes,
  setAssignedTasks,
  setLoading,
  setTasksError
} = reviewSlice.actions;

export default reviewSlice.reducer;

const handleAsyncThunk = async (dispatch: any, request: any, thunkAction?: any) => {
  try {
    dispatch(setLoading(true));
    const response = await request();
    if (thunkAction) dispatch(thunkAction(response.data.data));
    dispatch(setLoading(false));
    return response;
  } catch (error) {
    dispatch(setLoading(false));
    if (error?.response?.status) {
      dispatch(setTasksError(error.message));
    } else {
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error;
  }
};

export const fetchReviewList = createAsyncThunk(
  'tasks/fetchReviewList',
  async (
    {
      page = 0,
      type = '',
      searchTerm = '',
      sort = '',
      sortKey = '',
      startDate = '',
      endDate = '',
      teamId = '',
      reviewTypeId = '',
      traineeID = '',
    }: {  
        page?: number;
        employeeId?: string;
        searchTerm?: string;
        teamId?: string;
        sort?: string;
        sortKey?: string;
        type?: string;
        startDate?: any;
        endDate?: any;
        reviewTypeId?:any;
        traineeID?:string;
      },
    { dispatch }
  ) => {

    const params = {
      search: searchTerm,
      skip: `${page * PAGINATION_LINK.PER_PAGE_COUNT}`,
      limit: PAGINATION_LINK.PER_PAGE_COUNT,
      sort,
      sort_key: sortKey,
      type,
      team_uuid:teamId,
      start_date: startDate,
      end_date: endDate,
      review_type_filter_uuid: reviewTypeId,
      trainee_id : traineeID
    };

    const request = () => axios.get(Api.listReview, { params });
    await handleAsyncThunk(dispatch, request, setReviewList);
  }
);

export const fetchReviewDetails = createAsyncThunk(
  'tasks/fetchReviewDetails',
  async (
    {
      reviewUuid = ''

    }: {  

      reviewUuid?: string
      
    },
    { dispatch }
  ) => {

    const params = {
      review: reviewUuid
    };
    
    const request = () => axios.get(Api.reviewDetails, { params });
    await handleAsyncThunk(dispatch, request, setReviewDetails);
  }
);

export const addReview = createAsyncThunk('review/add', async (data: any, { dispatch }) => {
  try {
    await axios.post(Api.addReview, data);
    // dispatch(fetchTeamList());
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTasksError(error?.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});

export const listReviewTypes = createAsyncThunk('review/reviewtype', async (data: any, { dispatch }) => {
  try {
    const request = () => axios.get(Api.listReviewTypes);
    await handleAsyncThunk(dispatch, request, setReviewTypes);
    // dispatch(fetchTeamList());
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTasksError(error?.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});

// export const lsitAssignedTasks = createAsyncThunk('finalreview/assignedTasks', async (data: any, { dispatch }) => {
//   try {
//     const request = () => axios.get(Api.assignedTasks);
//     await handleAsyncThunk(dispatch, request, setAssignedTasks);
//     // dispatch(fetchTeamList());
//     return { success: true }; // Return success flag
//   } catch (error) {
//     if (error?.data?.length === 0) {
//       // Custom message handled
//       dispatch(setTasksError(error?.message));
//     } else {
//       // Specific error message handled
//       const errorMessages = Object.values(error?.data).flat();
//       dispatch(setTasksError(errorMessages));
//     }
//     throw error; // Throw the error to indicate failure
//   }
// });

export const lsitAssignedTasks = createAsyncThunk(
  'review/assignedTasks',
  async (
    {
      trainee_id = ''

    }: {  

      trainee_id?: string
      
    },
    { dispatch }
  ) => {

    const params = {
      trainee_id
    };
    
    const request = () => axios.get(Api.assignedTasks, { params });
    await handleAsyncThunk(dispatch, request, setAssignedTasks);
  }
);
