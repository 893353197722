import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import Api from '../utils/apiUrls';
// config
import { PAGINATION_LINK } from '../config-global';

interface Team {
  id: number;
  name: string;
}

interface TeamsState {
  isLoading: boolean;
  teamsData: Team[];
  detailsData: {},
  detailsError: {},
  error: {};
  locationList: [];
  totalData: 0;
  totalLocations: 0;
  totalVtHeads: 0;
}

const initialState: TeamsState = {
  isLoading: false,
  teamsData: [],
  detailsData: {},
  detailsError: {},
  error: {},
  locationList: [],
  totalData: 0,
  totalLocations: 0,
  totalVtHeads: 0
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
      state.error = {};
    },
    setTeamsList(state, action) {
      state.teamsData = action.payload.teams;
      state.locationList = action.payload.locations;
      state.totalData = action.payload.teams_count;
      state.totalLocations = action.payload.locations_count;
      state.totalVtHeads = action.payload.total_vt_head_count;
    },
    setTeamsError(state, action) {
      state.error = action.payload;
    },
    setTeamDetails(state, action) {
      state.detailsData = action.payload.team;
    },
    setDetailsError(state, action) {
      state.detailsError = action.payload;
    },
  },
});

export const { setLoading, setTeamsList, setTeamsError, setTeamDetails, setDetailsError } = teamsSlice.actions;
export default teamsSlice.reducer;

export const fetchTeamList = createAsyncThunk('teams/fetchTeamList', async ({ sort = "asc", sortKey = "team_name", page = 0, limit = PAGINATION_LINK.PER_PAGE_COUNT, location = '', searchKey = '' }: { sort?: string; sortKey?: string; page?: number; limit?: number; location?: string; searchKey?: string; }, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const params: Record<string|number, string|number> = {};
    if (sort) params.sort = sort;
    if (sortKey) params.sort_key = sortKey;
    if (page >= 0) params.skip = page * limit;
    if (limit) params.limit = limit;
    if (location) params.location_uuid = location;
    if (searchKey) params.search_term = searchKey;

    const response = await axios.get(Api.teams + Api.list,{ params });
    dispatch(setTeamsList(response?.data?.data));
    dispatch(setLoading(false));
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTeamsError(error.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTeamsError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});

export const fetchTeamDetails = createAsyncThunk('teams/fetchTeamDetails', async (team_uuid: string, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.get(`${Api.teams}${Api.details}/${team_uuid}`);
    dispatch(setTeamDetails(response?.data?.data));
    dispatch(setLoading(false));
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setDetailsError(error.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setDetailsError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});

export const editTeam = createAsyncThunk('teams/editTeam', async (data: any, { dispatch }) => {
  try {
    await axios.post(Api.teams + Api.edit, data);
    // dispatch(fetchTeamList());
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTeamsError(error.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTeamsError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});

export const addTeam = createAsyncThunk('teams/addTeam', async (data: any, { dispatch }) => {
  try {
    await axios.post(Api.teams + Api.add, data);
    // dispatch(fetchTeamList());
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTeamsError(error?.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTeamsError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});

export const deleteTeam = createAsyncThunk('teams/deleteTeam', async (data: any, { dispatch }) => {
  try {
    await axios.post(Api.teams + Api.delete, data);
    // dispatch(fetchTeamList());
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setTeamsError(error.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTeamsError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});
