import { BASE_URL } from "src/config-global";

const Api = {
  app: BASE_URL + process.env.REACT_APP_HOST_STORAGE_PATH,

  login: '/user/verify-token',
  logout: '/logout',
  edit: '/update',
  delete: '/delete',
  add: '/add',
  list: '/list',
  details: '/details',

  // dashboard
  dashboard: '/dashboard',
  graphs: '/graphs',

  // profile
  skills: '/skills',
  userSkills: '/user/skills',
  userData: '/profile',
  userProjects: '/user/projects',
  userCareer: '/user/experiences',

  // teams
  teams: '/teams',

  // employees
  employees: '/user',
  profilePic: '/upload-profile-picture',
  employeeList: '/employee-list',

  // location
  locations: '/locations',

  // session
  sessions: '/sessions',
  addAttendance: '/add-attendance',
  deleteAttendance: '/delete-attendance',
  importAttendance: '/import-csv-attendance',
  deleteDocument:'/delete-document',
  employeeSessions:'/employee-sessions',

  // roles
  roles: '/roles',

  // permissions
  permissions: '/permissions',
  rolePermissions: '/role-permissions',

  // interviews
  interviews: '/interviews',
  interviewProfile: '/interview',
  interviewsTaken: '/interviews-taken',
  addRound: '/round/add',
  roundDetails: '/round/details',
  feedback: '/submit-feedback',
  allRounds: '/rounds/list',
  deleteRound:'/round/delete',

  // attendance
  attendance: '/attendance',

  // tasks
  tasks: '/tasks',
  taskDetail: '/task/details',
  addTask: '/task/add',
  editTask: '/task/edit',
  deleteTask: '/task/delete',
  taskStatuses: '/task/statuses',
  assignedTasks: '/task/assigned',
  changeStatus: '/task/status-change',
  trainees:'/trainee/list',
  taskStatusLog: '/task/status-log',

  // daily status
  status: '/dailystatus',
  addStatus: '/dailystatus/add',
  deleteStatus: '/dailystatus/delete',

  // final review
  addReview: '/review/add',
  listReview: '/review/list',
  reviewDetails: '/review/showdetails',
  listReviewTypes:'/reviewtypes',
};

export default Api;
